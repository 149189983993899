import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Banner from '../components/Banner'
import { useLangContext } from '../context/lang.context'
import { sGridColumns, sSection, sTextGradientLight, s, colors } from '../style'

const content = {
  label: {
    en: 'Logistics Hub in Poland ',
  },
  title: {
    en: '<br/>Whether it’s transport by sea, air or rail.',
  },
  desc: {
    en: [
      'We deliver goods by sea using FCL and LCL transport. We connect Poland with the whole world. Thanks to our global network of partners, we carry out cargo transport between the ports of all continents.',
      'We also organize road, intermodal and rail transport, cooperating with reputable carriers in Poland, Europe and Asia. If the cargo has to be delivered quickly, the best solution is to use air freight. We make sure to offer our customers optimal solutions - fast and safe transport with competitive prices.',
    ],
  },
}

const banner = {
  label: {
    text: {
      en: `Data for 2024`,
    },
    color: colors.dark,
  },
  items: [
    {
      text: '165',
      unit: '+',
      label: {
        en: 'employees',
      },
    },
    {
      text: '471',
      unit: 'MLN',
      label: {
        en: 'PLN revenue in 2024',
      },
    },
    {
      text: '20023',
      unit: '',
      label: {
        en: 'customs clearance',
      },
    },
    {
      text: '300',
      unit: '+',
      label: {
        en: 'partner offices',
      },
    },
    {
      text: '1.150',
      unit: 'MLN',
      label: {
        en: 'kg of Airfreight loads',
      },
    },

    {
      text: '43514',
      unit: '',
      label: {
        en: 'TEU containers',
      },
    },
  ],
}

const IndexStats = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/regions/eu/intro_eu.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 10
            placeholder: BLURRED
            formats: JPG
          )
        }
      }
    }
  `)

  return (
    <>
      <section id='stats' css={sSection}>
        <h2 className='head'>
          <span className='label'>{content.label[lang]}</span>
          <span dangerouslySetInnerHTML={{ __html: content.title[lang] }} />
        </h2>
        <div css={sGridColumns}>
          {content.desc[lang]?.map((text, id) => (
            <p key={id} className='desc smaller'>
              {text}
            </p>
          ))}
        </div>
        <Banner
          label={banner.label}
          image={image}
          imagePosition={'50% 50%'}
          noSection
          blur
          // effect2
          // effect1
        >
          <div css={sItems}>
            {banner.items.map(({ text, unit, label }, id) => (
              <div key={id} css={sItem}>
                <div className='MainSection-wrapper'>
                  <div className='MainSection-item'>
                    <span className='MainSection-text'>{text}</span>
                    <span className='MainSection-unit'>{unit}</span>
                  </div>
                  <span className='MainSection-label'>{label[lang]}</span>
                </div>
              </div>
            ))}
          </div>
        </Banner>
      </section>
    </>
  )
}

const sItems = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '8rem',
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 12,
  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.sm_down]: {
    gap: '6rem',
    gridTemplateColumns: '1fr',
  },
}

const sItem = {
  display: 'flex',
  alignItems: 'center',
  [s.sm_down]: {
    justifyContent: 'center',
  },

  '.MainSection-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  '.MainSection-item': {
    fontSize: '6rem',
    fontWeight: 700,
    ...sTextGradientLight,
    [s.sm_down]: {
      fontSize: '4rem',
    },
  },

  '.MainSection-text': {
    letterSpacing: '-0.05em',
  },

  '.MainSection-unit': {
    fontSize: '2rem',
  },

  '.MainSection-label': {
    textTransform: 'uppercase',
    color: colors.light,
    fontSize: '1rem',
    fontWeight: 700,
  },
}

export default IndexStats
